// Safari browser

.safari:root
  select[multiple]
    block-size: 20px

  #page #content main#main
    .content section.list > .table
      &.tree :is(.tbody .tr .td, .thead .tr .th)
        &.file::marker, &.tree::marker
          font-size: 16px
      \:is(.td, .th).container meter::-webkit-meter-bar
        block-size: 8px
    &.dash_view .content .item .main .status :is(.progress, .skills) meter::-webkit-meter-bar
      block-size: 14px
