// Mathtutor styles

@import gradients
@import colors

/* front page sidebar */
#mathtutor:root #front_page aside#front_sidebar
  aside
    @each $id, $color in 'students' $orange, 'instructors' $green, 'alternate' $yellow
      &##{$id}
        background: $color darken-gradient(lighten($color, $half-step))
  > a aside
    @each $id, $color in 'students' $orange, 'instructors' $green, 'alternate' $yellow
      &##{$id}
        border-color: lighten($color, $half-step)
        background: $color darken-gradient(lighten($color, $half-step))
        &:hover, &:focus-visible
          border-color: darken($color, $half-step)
          background: darken($color, $step) darken-gradient(darken($color, $half-step))
        &:active
          border-color: darken($color, $half-step)
          background: $color darken-gradient(lighten($color, $half-step), $step, 'to top')
