/*
 * BUTTONS
 */

@import gradients
@import colors

/* regular buttons */
a.button, .button a, button:not(.link)
  inline-size: max-content

.button
  display: inline-block
  min-inline-size: fit-content
  &.split
    margin-inline-end: auto
  img
    margin-inline-end: var(--button-padding)
  input[type="submit"], button, a
    display: inline-block
    outline-offset: -1px
    border-radius: var(--button-radius)
    box-shadow: 0px 2px 2px $darkgray
    min-block-size: 28px
    font-size: 12px
    font-weight: initial
    cursor: pointer
    &:active
      box-shadow: 0px 1px 1px $darkgray
  input[type="submit"], button, a
    padding: var(--button-padding)
    text-decoration: none
  &.large
    display: block
    margin-inline: auto
    margin-block: var(--padding)
    text-align: center
    a, button
      box-shadow: 0px 3px 3px $darkgray
      inline-size: fit-content
      padding-inline: 8px
      padding-block: 7px
      &:active
        box-shadow: 0px 2px 2px $darkgray
    &.icon :is(a, button)
      display: inline-flex
      justify-content: center
      align-items: center
      padding-inline: 6px
      padding-block: 5px
      line-height: 18px
      img
        block-size: 20px
      span
        text-align: center
  @each $selector, $color, $text_color in 'input[type="submit"]' darken($blue, $step) white, 'button[type="submit"]' darken($blue, $step) white, 'button[type=button]' $lightgray black, 'button[type=submit][formmethod=get]' $lightgray black, 'a' $lightgray black, '&#run a' darken($green, $step) black, '&.google a' white black
    #{$selector}
      border: 1px outset lighten($color, $half-step)
      background: lighten($color, $half-step) lighten-gradient($color)
      color: $text_color
      text-shadow: 1px 1px 2px invert($text_color)
      &:hover, &:focus-visible
        border-color: $color
        color: $text_color
        background: darken($color, $half-step) darken-gradient($color)
      &:active
        border: 1px inset $color
        color: $text_color
        background: lighten($color, $half-step) lighten-gradient($color, $step, 'to top')

#planets:root
  .button a, .button button, a.button, button.button, input[type="submit"], .button button[type="submit"]
    box-shadow: 0px 2px 2px $darkgray
    border-radius: var(--button-radius)
    padding-inline: calc(2 * var(--button-padding))
    padding-block: calc(var(--button-padding) / 2)
    text-align: center
    font-size: 14px
    line-height: 23px
    text-transform: lowercase
    img
      display: none
  @each $selector, $color, $text_color in 'input[type="submit"]' gold black, '.button button[type="submit"]' gold black, 'a.button' $gray white, '.button a' $gray white, 'button.button[type="button"]' $gray white
    #{$selector}
      border: 1px outset $color
      background: $color
      color: $text_color
      text-shadow: 1px 1px 2px invert($text_color)
      &:hover
        border-color: $color
        background: darken($color, $half-step)
      &:active
        border: 1px inset $color
        background: $color
