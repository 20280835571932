// Firefox browser

@import colors

.firefox:root
  *:focus-visible
    outline: auto

  meter
    border-color: $lightgray
    &:-moz-meter-sub-optimum::-moz-meter-bar
      border-radius: 20px
      background: darken($yellow, $half-step) lighten-gradient(darken($yellow, $half-step)) no-repeat
    &:-moz-meter-optimum::-moz-meter-bar
      border-radius: 20px
      background: $green lighten-gradient($green) no-repeat

  input[type="text"], input[type="password"], input[type="email"], input[type="search"], input[type="number"], input[type="url"], input[type="date"]
    padding-block: 1px

  input[type="file"]::file-selector-button
    border: 1px solid $gray
    padding-block: 2px

  input[type="checkbox"]
    vertical-align: 0px

  select
    border: 1px solid $gray
    border-radius: 2px
    &[disabled]
      background-color: lighten($lightgray, 3 * $half-step)
    &[multiple]
      padding-block: 0px

  .button
    min-inline-size: -moz-fit-content
    &.large
      a, button
        inline-size: -moz-fit-content

  #page #content main#main .content section.list > .table.tree
    .tbody .tr .td, .thead .tr .th
      &.file::marker, &.tree::marker
        font-size: 16px

#planets.firefox:root
  meter
    border-color: gold
    &:-moz-meter-sub-optimum::-moz-meter-bar
      background: gold lighten-gradient(gold) no-repeat
