// Tutorshop styles

@import gradients
@import colors

#tutorshop:root #page.front aside#front_sidebar aside
  @each $id, $color, $text_color in 'students' $orange black, 'instructors' $green black, 'alternate' $yellow black
    &##{$id} .button a
      border-color: lighten($color, $half-step)
      background: $color darken-gradient(lighten($color, $half-step))
      color: $text_color
      text-shadow: 1px 1px 2px invert($text_color)
      &:hover, &:focus-visible
        border-color: darken($color, $half-step)
        background: darken($color, $step) darken-gradient(darken($color, $half-step))
      &:active
        border-color: darken($color, $half-step)
        background: $color darken-gradient(lighten($color, $half-step), $step, 'to top')
  &#students .button.google a
    border-color: white
    background: white
    &:hover, &:focus-visible
      background: darken(white, $half-step) darken-gradient(white)
    &:active
      background: white
