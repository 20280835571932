$phzh-background: white
$phzh-box-background: #FAFAFA
$phzh-red1:     #EE3647
$phzh-red2:     #F2726F
$phzh-red3:     #F69892
$phzh-blue1:    #26BAC7
$phzh-blue2:    #79CDD0
$phzh-blue3:    #D1ECEC
$phzh-magenta1: #ED1C91
$phzh-gray1:    #BEBCA6
$phzh-gray2:    #E0E6DD
$phzh-gray3:    #F1ECE2
$phzh-yellow1:  #FEC227
$phzh-green1:   #B1D270

$phzh-header-height: 55px
$phzh-header-border-width: 5px
$phzh-header-border-top: 13px

@mixin phzh-title-font
    font-size: 20px!important
    font-weight: 400!important
    //line-height: 38px!important
    white-space: nowrap

@mixin phzh-panel-accordeonbody
    background: $phzh-background

body
    background: $phzh-background!important

aside
    background: $phzh-background!important

#page aside#sidebar, #front_page aside#front_sidebar
    margin-top: $phzh-header-height

aside #menu
    background: $phzh-box-background !important
    border-top: $phzh-header-border-top solid $phzh-blue1!important
    margin-bottom: $phzh-header-border-top!important
    color: black!important
    padding-top: 0px!important

aside #menu h3
    @include phzh-title-font
    overflow-x: hidden
    overflow-y: hidden
    text-overflow: elipsis

#page aside#sidebar nav#menu ul
    border: none!important

#page aside#sidebar nav#menu li
    border: none!important

#page aside#sidebar nav#menu li a
    color: black!important
    text-shadow: none!important
    text-decoration: underline!important

    #page aside#sidebar nav#menu.system li a:hover, #page aside#sidebar nav#menu.system li.current a
#page aside#sidebar nav#menu.school li a:hover, #page aside#sidebar nav#menu.school li.current a
    background-color: $phzh-gray2!important

aside aside
    background: $phzh-box-background !important
    border-top: $phzh-header-border-top solid $phzh-gray1!important
    margin-bottom: $phzh-header-border-top!important

aside aside h3
    @include phzh-title-font

aside div.button.large
    text-align: left

aside div.button.large:hover
    text-align: left

aside div.button.large a
    text-decoration: underline
    box-shadow: none
    border: none
    color: black
    background: none!important

aside div.button.large a:hover
    text-decoration: underline
    box-shadow: none
    border: none
    color: black
    background: $phzh-gray3

div.google
    display: none!important

#main
    background: $phzh-background

#banner
    background: $phzh-background ! important
    border-bottom: $phzh-header-border-width solid black
    height: $phzh-header-height!important

#banner button
    background: none!important
    box-shadow: none!important
    border: none !important
    border-radius: 0px!important
    color: black ! important
    text-shadow: none!important

#banner button:hover
    background: none!important
    box-shadow: none!important
    border: none !important
    border-radius: 0px!important
    background: $phzh-gray2 !important
    text-shadow: none!important

#main
    background: $phzh-background!important

#footer
    background: $phzh-background!important
    margin-top: 20px!important
    border-top: 1px solid $phzh-gray3

#front_content
    background: $phzh-background!important

#front_page, #page
  aside#front_sidebar, aside#sidebar
    background: none!important

    .sidebar_panel > h3
        word-wrap: break-word
        white-space: normal

#page #front_content, #page #content
    background: $phzh-background!important

#page #content nav#breadcrumbs
    background: $phzh-background!important

#page #content nav#breadcrumbs li:last-child
    font-weight: 600

#page #content main#main.dash_view .content > section.accordion
    border: none!important

#page #content main#main.dash_view .content h3
    @include phzh-title-font
    background: $phzh-background!important
    text-shadow: none!important
    color: black!important

#page #content main#main.dash_view .content .item
    background: $phzh-box-background!important
    box-shadow: none!important
    border-radius: 0px
    border-top:  $phzh-header-border-top solid $phzh-magenta1

#page #content main#main.dash_view .content .item:hover
    transform: none!important

#page #content main#main .content section.list > .table :is(.thead, .tfoot) .tr
    background: $phzh-green1!important
    text-shadow: none!important
    color: black!important

#page #content main#main.tab_view > .content ul.tabs li+li
    margin-left: 30px

#page #content main#main.tab_view > .content ul.tabs a
   background: $phzh-background!important
   border: none!important

#page #content main#main.tab_view > .content ul.tabs li.ui-tab
   border-bottom: $phzh-header-border-width solid $phzh-background!important
   border-radius: 0px!important
   margin-bottom: 5px

#page #content main#main.tab_view > .content ul.tabs li.ui-tab.ui-tabs-active
   border-bottom: $phzh-header-border-width solid black!important

#page #content main#main.tab_view > .content ul.tabs li.ui-tabs-active a
   font-weight: 600!important
   text-shadow: none!important

#page #content main#main.tab_view > .content .panels
    background: $phzh-box-background!important

.button input[type="submit"], .button button, .button a
    border-radius: 0px!important
    box-shadow: none!important
    border: none!important
    text-shadow: none!important
    color: black!important
    background: $phzh-gray3!important
    a
        text-decoration: underline!important

.button button[type="submit"]
    background: $phzh-blue2!important

.button button[type="submit"][formmethod="get"]
    background: $phzh-gray2!important

#front_page
    #front_content
        #login_modal
            #login_dialog
                background-color: $phzh-box-background!important
                border-radius: 0px
                border: none!important
                border-top: $phzh-header-border-top solid $phzh-gray1!important
                aside
                    background-color: $phzh-box-background!important
                h2
                    color: black
                form .table
                    color: black

#page
  aside#sidebar
    nav#menu
      @each $class, $color in 'system' $phzh-gray2, 'school' $phzh-gray2, 'student' $phzh-gray2
        &.#{$class}
          li a:hover, li.current a
            background: $color

#front_page, #page
  #front_content, #content
    header#header
      aside#messages
        @each $selector, $text_color, $color in '#flashnotice' white $phzh-green1, '#flashalert' white $phzh-red1
          #{$selector}
            border-radius: 0px
            border-top: $phzh-header-border-top solid $color
            display: none
            color:          black
            background-color: lighten($color, 20%)