// Android

.android:root
  input[type="checkbox"]
    vertical-align: -3px

  #page main
    section#content .content section.list
      > .table :is(.td, .th)
        @each $class, $inline-size in 'hidden' 0px, 'arrows' 16px, 'icon' 28px
          &.#{$class}
            inline-size: $inline-size
            padding-inline-start: 2px
      &#course_table > .table .tfoot .th.name
        min-inline-size: 0px
